import React, { useCallback, useState } from 'react';
import { Button, ButtonProps } from '../button';
import { Popover } from '@material-ui/core';

export type ButtonWithMenuProps = {
    buttonType: ButtonProps['type'];
    buttonTitle: ButtonProps['title'];
    buttonSize?: ButtonProps['size'];
    disabled?: ButtonProps['disabled'];
    needStartIcon?: boolean;
};

export const ButtonWithMenu: React.FC<ButtonWithMenuProps> = ({
    buttonType = 'primary',
    buttonTitle,
    children,
    buttonSize = 'medium',
    disabled = false,
    needStartIcon,
}) => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const switchMenu = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            const isOpened = Boolean(anchor);
            if (!isOpened) {
                setAnchor(event.currentTarget);
            }
        },
        [anchor, setAnchor]
    );

    const calculateMaxHeight = useCallback(
        (element: HTMLElement) => {
            if (anchor) {
                const { top, height } = anchor.getBoundingClientRect();
                element.style.maxHeight = `calc(100% - ${top + height}px - 20px)`;
            }
        },
        [anchor]
    );
    return (
        <React.Fragment>
            <Button
                type={buttonType}
                title={buttonTitle}
                onClick={switchMenu}
                size={buttonSize}
                disabled={disabled}
                needStartIcon={needStartIcon}
            />

            <Popover
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                open={Boolean(anchor)}
                onEntering={calculateMaxHeight}
                onClick={switchMenu}
            >
                {children}
            </Popover>
        </React.Fragment>
    );
};
