import React from 'react';
import { GridColDef, GridFilterItem } from '@material-ui/x-grid';
import { COLUMN_SETTING_NAME } from './constants';

export const getColumns = (columns: GridColDef[], localStorageCache?: string) => {
    if (!columns.length) {
        return columns;
    }

    const resultColumns: GridColDef[] = [];

    if (localStorageCache) {
        try {
            const savedColumnsJSON = localStorage.getItem(localStorageCache) as string;
            const savedColumns: GridColDef[] | null = JSON.parse(savedColumnsJSON);
            const updatedFromLocalStorageColumns = columns.map(
                ({ hide = false, width = 100, field, ...restColumn }: GridColDef & { required?: boolean }) => {
                    const savedColumn = savedColumns?.find((saved) => field === saved.field);

                    return {
                        ...restColumn,
                        field,
                        hide: savedColumn ? savedColumn.hide : hide,
                        width: savedColumn ? savedColumn.width : width,
                    };
                }
            );

            resultColumns.push(...updatedFromLocalStorageColumns);
        } catch (error) {
            console.warn(`Wrong value in localStorage. Please check localStorage.getItem('${localStorageCache}')`);
            resultColumns.push(...columns);
        }
    } else {
        resultColumns.push(...columns);
    }

    return resultColumns;
};

export const getSettingsColumn = (settingsComponent: React.ReactNode, width: number = 100) =>
    ({
        field: COLUMN_SETTING_NAME,
        headerName: ' ',
        width,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideSortIcons: false,
        resizable: false,
        headerAlign: 'center',
        renderHeader: () => settingsComponent,
    } as const);

const findColumnNameByField = (columns: GridColDef[], filterField?: string) =>
    columns.find(({ field }) => filterField === field)?.headerName;

export const defaultFiltersMapper = (filters: GridFilterItem[], columns: GridColDef[]) =>
    filters.reduce(
        (acc: unknown[], { columnField, operatorValue, value }) => [
            ...acc,
            {
                key: findColumnNameByField(columns, columnField),
                singleOperators: [
                    {
                        filterCompareType: operatorValue,
                        value: value || null,
                    },
                ],
            },
        ],
        []
    );
