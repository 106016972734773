import React, { useState, useEffect, useCallback, useMemo, useRef, Dispatch, SetStateAction } from 'react';

import classnames from 'classnames';
import {
    XGrid,
    GridColumns,
    GridRowsProp,
    ruRUGrid,
    GridPagination,
    LicenseInfo,
    GridSelectionModel,
    GridSortModelParams,
    useGridApiRef,
    GridSortItem,
    GridSortModel,
    GridColumnHeaderParams,
    XGridProps,
    GridFilterModel,
    GridFilterItem,
    GridColDef,
    GridRowParams,
} from '@material-ui/x-grid';
import { IconButton, Paper } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Settings } from '@material-ui/icons';

import { getColumns, getSettingsColumn, defaultFiltersMapper } from './utils';
import { deepEqual } from '../../utils/deep-equal';
import { SettingColumnMenu } from './setting-column-menu';
import { COLUMN_CHECKBOX_NAME, COLUMN_SETTING_NAME } from './constants';
import { CUSTOM_COLUMN_TYPES } from './filters/custom-column-types';

import './x-grid-table.scss';
import { Sort } from './Components/Sort';
import { SettingMenu } from './Components/SettingMenu/SettingMenu';
import { Checkbox } from '../Checkbox';

LicenseInfo.setLicenseKey(
    'e726e4a0056c2c09741c33b04e034e97T1JERVI6MjM3NzksRVhQSVJZPTE2NTAxOTYxNDIwMDAsS0VZVkVSU0lPTj0x'
);

export type TableParams = {
    size: number;
    page: number;
    sort: GridSortItem | undefined;
    filters: unknown[];
};

export type CustomColumn = GridColDef & {
    required?: boolean;
};
export type RowId = string | number;

export type CheckboxState = 'selectAll' | 'partiallySelected' | 'takeOutAll';

export type XGridTableProps = XGridProps & {
    columns: GridColumns;
    rows: GridRowsProp;
    rowsPerPageOptions?: number[];
    /**
     * устанавливает количество строк в таблице
     */
    pageSize: number;
    /**
     * устанавливает начальную страницу
     */
    pageStart: number;
    /**
     * mode сортировки и пагинации
     */
    mode: 'client' | 'server';
    /**
     * устанавливает сортировку по умолчанию
     */
    initSortModel?: GridSortModel;
    onSelectionModelChange?: XGridProps['onSelectionModelChange'];
    filterMapper?: (filters: GridFilterItem[], columns: GridColumns) => unknown[];
    className?: string;
    /**
     * устанавливает выбранные строки
     */
    selectionModel?: RowId[];
    onParametersChange?: (params: TableParams) => void;
    onSelectAll?: () => void;
    onRowDoubleClick?: XGridProps['onRowDoubleClick'];
    onRowClick?: XGridProps['onRowClick'];
    /**
     * устанавливает количество строк
     */
    rowCount?: number;
    hideFooterSelectedRowCount?: boolean;
    withSettings?: boolean;
    renderCustomColumn?: (params: {
        columns: CustomColumn[];
        onVisibilityChange: (field: string) => void;
        onAddCustomColumn: XGridTableProps['onAddCustomColumn'];
        onEditCustomColumn: XGridTableProps['onEditCustomColumn'];
        onRemoveCustomColumn: XGridTableProps['onRemoveCustomColumn'];
    }) => React.ReactNode;
    /**
     * Уникальный ключ для сохранения настроек отображения колонок в local storage
     */
    localStorageCache?: string;
    /**
     * Отключает меню колонки, тем самым не отключает фильтрацию
     * @default true
     */
    disableFiltering?: boolean;
    checkboxSelection?: XGridProps['checkboxSelection'];
    onAddCustomColumn?: () => void;
    onEditCustomColumn?: (column: CustomColumn) => void;
    onRemoveCustomColumn?: (column: CustomColumn) => void;
    isRowSelectable?: (params: GridRowParams) => boolean;
    loading?: boolean;
    customPagination?: ({
        setParamsState,
    }: {
        setParamsState: Dispatch<
            SetStateAction<
                Omit<TableParams, 'sort'> & {
                    sort: GridSortModelParams['sortModel'];
                }
            >
        >;
    }) => React.ReactElement<any, any>;
};

export const XGridTable: React.FC<XGridTableProps> = ({
    columns: outputColumns,
    rows,
    rowsPerPageOptions,
    pageSize: pageSizeProp,
    pageStart,
    disableFiltering = true,
    onParametersChange,
    onSelectionModelChange,
    mode = 'client',
    initSortModel,
    onSelectAll,
    filterMapper = defaultFiltersMapper,
    rowCount,
    withSettings,
    renderCustomColumn,
    localStorageCache,
    hideFooterSelectedRowCount,
    selectionModel: selectionModelProps,
    className,
    onRowDoubleClick,
    onRowClick,
    checkboxSelection = true,
    onAddCustomColumn,
    onEditCustomColumn,
    onRemoveCustomColumn,
    isRowSelectable,
    loading,
    customPagination,
    ...rest
}) => {
    const gridApiRef = useGridApiRef();
    const [firstTime, setFirstTime] = useState(true);
    const [paramsState, setParamsState] = useState<
        Omit<TableParams, 'sort'> & { sort: GridSortModelParams['sortModel'] }
    >({
        size: pageSizeProp,
        page: pageStart,
        sort: initSortModel || [],
        filters: [],
    });
    const [columns, setColumns] = useState<GridColumns>(getColumns(outputColumns, localStorageCache));
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const columnsForSettings: CustomColumn[] = useMemo(
        () => columns.filter((el): boolean => el.headerName?.trim() !== ''),
        [columns]
    );

    const refMenuAnchor = useRef<HTMLDivElement | null>(null);

    const saveColumnsToLocaleStorage = useCallback(
        (columns: GridColumns) => {
            const withoutCheckbox = columns.filter(
                (el) => ![COLUMN_CHECKBOX_NAME, COLUMN_SETTING_NAME].includes(el.field)
            );
            setColumns(withoutCheckbox);
            if (localStorageCache) {
                localStorage.setItem(localStorageCache, JSON.stringify(withoutCheckbox));
            }
        },
        [localStorageCache]
    );

    const handleSettingsItemClick: (field: string) => void = useCallback(
        (field: string) => {
            const allColumns = gridApiRef.current.getAllColumns();
            const newColumns = allColumns.map((el) => {
                if (el.field === field) {
                    return { ...el, hide: !el.hide };
                }
                return el;
            });

            saveColumnsToLocaleStorage(newColumns);
        },
        [saveColumnsToLocaleStorage]
    );

    const tableColumns = useMemo(
        () =>
            withSettings
                ? columns.concat(
                      getSettingsColumn(
                          <div>
                              <IconButton size="small" onClick={() => setIsOpenMenu(true)} aria-label="Settings">
                                  <Settings />
                              </IconButton>
                          </div>,
                          39
                      )
                  )
                : columns,
        [withSettings, columns]
    );
    const handlePageSizeChange = (size: number): void => {
        setParamsState((prevState) => ({ ...prevState, size }));
    };

    const handlePageChange = (page: number): void => {
        setParamsState((prevState) => ({ ...prevState, page }));
    };

    const handleSortModelChange = (newSortModel: GridSortModel): void => {
        if (!deepEqual(paramsState.sort, newSortModel)) {
            setParamsState((prevState) => ({ ...prevState, sort: newSortModel }));
        }
    };

    const handleFilterModelChange = (filterModel: GridFilterModel): void => {
        const filters = filterMapper(filterModel?.items || filterModel, columns);
        setParamsState((prevState) => ({ ...prevState, filters }));
    };

    useEffect(() => {
        if (!firstTime && onParametersChange) {
            onParametersChange({ ...paramsState, sort: paramsState.sort[0] });
            return;
        }
        setFirstTime(false);
    }, [paramsState]);

    const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
        if (onSelectionModelChange) {
            onSelectionModelChange(selectionModel);
        }
    };

    useEffect(() => {
        setColumns(getColumns(outputColumns, localStorageCache));
    }, [outputColumns, localStorageCache]);

    return (
        <Paper ref={refMenuAnchor} className={classnames('x-grid-table-wrapper', className)}>
            <XGrid
                {...rest}
                // required prop
                columns={tableColumns}
                headerHeight={36}
                rows={rows}
                localeText={{
                    ...ruRUGrid,
                    noRowsLabel: 'Нет записей',
                    footerRowSelected: (count: number) => (checkboxSelection ? `Выбрано: ${count}` : null),
                }}
                density="standard"
                rowHeight={44}
                columnBuffer={0}
                scrollEndThreshold={0}
                sortingOrder={['asc', 'desc']}
                // required prop end
                checkboxSelection={checkboxSelection}
                // custom props start
                apiRef={gridApiRef}
                rowsPerPageOptions={rowsPerPageOptions}
                pageSize={pageSizeProp ? pageSizeProp : paramsState.size}
                page={paramsState.page}
                onSelectionModelChange={handleSelectionModelChange}
                sortingMode={mode}
                paginationMode={mode}
                filterMode={mode}
                rowCount={rowCount}
                hideFooterSelectedRowCount={hideFooterSelectedRowCount}
                sortModel={paramsState.sort}
                selectionModel={selectionModelProps}
                disableColumnMenu={disableFiltering}
                columnTypes={CUSTOM_COLUMN_TYPES}
                disableColumnSelector={true} // блокировка скрытия колонок в меню
                disableMultipleColumnsSorting={true}
                loading={loading}
                pagination={true}
                disableSelectionOnClick={true}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                onSortModelChange={handleSortModelChange}
                onRowDoubleClick={onRowDoubleClick}
                onRowClick={onRowClick}
                onFilterModelChange={handleFilterModelChange}
                onColumnHeaderClick={(params: GridColumnHeaderParams) => {
                    if (params.field !== COLUMN_CHECKBOX_NAME) {
                        return;
                    }

                    if (!gridApiRef.current.getSelectedRows().size) {
                        onSelectAll?.();
                    }
                }}
                showCellRightBorder={false}
                showColumnRightBorder={false}
                isRowSelectable={isRowSelectable}
                components={{
                    ColumnSortedAscendingIcon(props) {
                        return <Sort {...props} sortAsc selected />;
                    },
                    ColumnSortedDescendingIcon(props) {
                        return <Sort {...props} selected />;
                    },
                    ColumnUnsortedIcon() {
                        return <Sort />;
                    },
                    Checkbox,
                    LoadingOverlay: () => (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                color: '#4C5ECF',
                                zIndex: 300,
                            }}
                        />
                    ),
                    Pagination: () =>
                        customPagination ? (
                            customPagination({
                                setParamsState,
                            })
                        ) : (
                            <GridPagination
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                labelRowsPerPage="Строк на странице"
                                labelDisplayedRows={({
                                    from,
                                    to,
                                    count,
                                }: {
                                    from: number;
                                    to: number;
                                    count: number;
                                }) => `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`}
                            />
                        ),
                }}
                hideFooterRowCount={true}
            />
            <SettingColumnMenu
                open={isOpenMenu}
                handleClose={() => setIsOpenMenu(false)}
                menuAnchor={refMenuAnchor.current}
            >
                {renderCustomColumn ? (
                    renderCustomColumn({
                        columns: columnsForSettings,
                        onVisibilityChange: handleSettingsItemClick,
                        onAddCustomColumn,
                        onEditCustomColumn,
                        onRemoveCustomColumn,
                    })
                ) : (
                    <div>
                        <SettingMenu
                            editableItems={columnsForSettings.filter((i) => i.editable)}
                            items={columnsForSettings.filter((i) => !i.editable)}
                            onCheckboxClick={handleSettingsItemClick}
                            onEditCustomColumn={(col) => onEditCustomColumn?.(col)}
                            onRemoveCustomColumn={(col) => onRemoveCustomColumn?.(col)}
                        />
                    </div>
                )}
            </SettingColumnMenu>
        </Paper>
    );
};
